<template>
    <div>
        <AError :api="this.api" />
        <!-- <v-skeleton-loader type="card" v-if="this.api.isLoading">
        </v-skeleton-loader> -->
        <div
            class="px-2">
            <v-combobox
                dense
                outlined
                v-model="cs"
                label="Customer Service ( Optional )"
                :items="csOptions"
                item-text="username"
                item-value="id"
                :loading="this.api.isLoading"
                @change="updateParentValue"
                return-object>
            </v-combobox>
        </div>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';

export default {
    components: {
        //
    },
    computed: mapState({
        //
    }),
    props: [
        'fieldValue'
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingClient: false,
        isLoadingSubscription: false,
        actionDrop: false,
        role: null,
        page: 1,
        csOptions:null,
        cs:null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        
        client: null,
        staff: null,
        subscription: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==='getStaffListByRole') {
                this.csOptions = resp.data;

            }
            this.api.isLoading = false;
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.cs = this.$_.clone(this.fieldValue);
            this.$api.fetch(this.fetchCSOptions());
        },
        fetchCSOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/role/staff/customer_service";
            return tempApi;
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        updateParentValue() {
            this.$emit('updateData',this.cs);
        },
        
    },
}
</script>

<style scoped>

</style>