<template>
    <div>
        <v-text-field
            prepend-icon="mdi-calendar"
            v-model="data"
            @click:prepend="openModal"
            :label="label"
            dense 
            outlined
            @click="openModal">
        </v-text-field>
        <v-dialog
            v-model="isOpenModal"
            height="auto"
            width="auto">
            <v-card 
                class="pa-3">
                <div
                    class="my-1">
                    <p
                        class="d-flex justify-start mt-3">
                        {{ this.label }}
                    </p>
                    <div
                        class="d-flex justify-start">
                        <v-date-picker
                            v-model="data"
                            :landscape="true"
                            :readtive="true">
                        </v-date-picker>
                    </div>
                </div>
                <slot 
                    name="footer">
                    <div
                        class="d-flex justify-end my-3"
                        color="white">
                        <v-btn
                            color="primary"
                            plain
                            @click="clearData">
                            Clear
                        </v-btn>
                        <v-btn
                            @click="updateData"
                            color="primary">
                            Confirm
                        </v-btn>
                    </div>
                </slot>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    'label','data'
    ],
    data: () => ({
        isOpenModal:false,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
           
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        openModal() {
            this.isOpenModal = true;
        },
        closeModal() {
            this.isOpenModal = false;
        },
        clearData() {
            this.$emit('updateDateData',null);
            this.isOpenModal = false;
        },
        updateData() {
            this.$emit('updateDateData',this.data);
            this.isOpenModal = false;
        },
        convertTimeZone(time){
            return this.$moment(time).format('LL');
        }
    }
}
</script>